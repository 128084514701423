import React from 'react';
import styled from 'styled-components';
import {Menu, Button} from 'antd';
import {useOutlet} from 'reconnect.js';
import qs from 'query-string';
import {BookOpen} from '@styled-icons/boxicons-regular/BookOpen';
import {Plus} from '@styled-icons/boxicons-regular/Plus';
import {Minus} from '@styled-icons/boxicons-regular/Minus';
import * as JStorage from 'rev.sdk.js/Actions/JStorage';
import RichTextPreview from 'rev.sdk.js/Components/RichTextPreview';
import withPageEntry from '../../withPageEntry';
import * as AppActions from '../../AppActions';

const TITLE = '福利搶鮮報';
const ROUTES = [
  {
    key: '/welfare?group=tba',
    label: '會員相關福利',
    children: [
      {
        key: '/welfare?group=tba&category=items',
        label: '公會福利事項',
        children: [{key: '', label: '會員服務-福利搶鮮報-公會福利事項'}],
      },
      {
        key: '/welfare?group=tba&category=food',
        label: '食',
        children: [{key: '', label: '會員服務-福利搶鮮報-食'}],
      },
      {
        key: '/welfare?group=tba&category=stay',
        label: '住',
        children: [{key: '', label: '會員服務-福利搶鮮報-住'}],
      },
      {
        key: '/welfare?group=tba&category=clothes',
        label: '衣',
        children: [{key: '', label: '會員服務-福利搶鮮報-衣'}],
      },
      {
        key: '/welfare?group=tba&category=trip',
        label: '行',
        children: [{key: '', label: '會員服務-福利搶鮮報-行'}],
      },
      {
        key: '/welfare?group=tba&category=entertainment',
        label: '娛樂',
        children: [{key: '', label: '會員服務-福利搶鮮報-娛樂'}],
      },
      {
        key: '/welfare?group=tba&category=medical',
        label: '醫療保健',
        children: [{key: '', label: '會員服務-福利搶鮮報-醫療保健'}],
      },
      {
        key: '/welfare?group=tba&category=book',
        label: '圖書資訊',
        children: [{key: '', label: '會員服務-福利搶鮮報-圖書資訊'}],
      },
      {
        key: '/welfare?group=tba&category=finance',
        label: '理財',
        children: [{key: '', label: '會員服務-福利搶鮮報-理財'}],
      },
      {
        key: '/welfare?group=tba&category=insurance',
        label: '保險',
        children: [{key: '', label: '會員服務-福利搶鮮報-保險'}],
      },
      {
        key: '/welfare?group=tba&category=others',
        label: '其他',
        children: [{key: '', label: '會員服務-福利搶鮮報-其他'}],
      },
      {
        key: '/welfare?group=tba&category=firm',
        label: '廠商專區',
        children: [{key: '', label: '會員服務-福利搶鮮報-廠商專區'}],
      },
    ],
  },
];

function Welfare(props) {
  const params = qs.parse(props.location.search);
  const [dimension] = useOutlet('dimension');
  const mobile = dimension.rwd === 'mobile';
  const [mobileNavVisible, setMobileNavVisible] = React.useState(false);
  const [records, setRecords] = React.useState(null);
  const [expands, setExpands] = React.useState(null);

  const handleCustomComponent = (search) => {
    return [false, null];
  };

  const getRouteInstance = ({group, category}) => {
    let element;

    if (group) {
      element = ROUTES.find((item) => item.key.indexOf(group) > -1);

      if (category) {
        element = element.children.find(
          (item) => item.key.indexOf(category) > -1,
        );
      }
    }

    return element;
  };

  React.useEffect(() => {
    async function fetchData() {
      const params = qs.parse(props.location.search);
      let route = getRouteInstance(params);

      if (!route) {
        setRecords(null);
        setExpands(null);
        return;
      }

      AppActions.setLoading(true);

      setRecords(null);
      setExpands(null);

      try {
        if (route.children.length > 0) {
          const {results = []} = await JStorage.fetchDocuments(
            'welfare',
            {
              labels: {$in: route.children.map((c) => c.label)},
              public: true,
              $or: [
                {
                  onshelf_start_date: {
                    $lte: new Date().toLocaleDateString('sv'),
                  },
                  onshelf_end_date: {
                    $gte: new Date().toLocaleDateString('sv'),
                  },
                },
                {
                  onshelf_start_date: {$exists: false},
                  onshelf_end_date: {$exists: false},
                },
                {
                  onshelf_start_date: '',
                  onshelf_end_date: '',
                },
                {
                  onshelf_start_date: null,
                  onshelf_end_date: null,
                },
              ],
            },
            ['-created'],
            {offset: 0, limit: 120},
          );
          setRecords(results);
          setExpands(results.map((c) => ({label: c.title, expand: false})));
        }
      } catch (ex) {
        console.warn(ex);
      }
      AppActions.setLoading(false);
    }

    fetchData();
  }, [props.location.search]);

  return (
    <Wrapper mobile={mobile}>
      <div className="menu">
        <Menu mode="inline" defaultOpenKeys={[`root-${0}`]}>
          <Menu.Item className="header">{TITLE}</Menu.Item>
          {ROUTES.map((item, index) => {
            if (item.children.length > 0) {
              return (
                <Menu.SubMenu
                  key={`root-${index}`}
                  className="route"
                  title={item.label}>
                  {item.children.map((subroute, index) => (
                    <Menu.Item
                      key={`subroute-${index}`}
                      onClick={() => AppActions.navigate(subroute.key)}>
                      {subroute.label}
                    </Menu.Item>
                  ))}
                </Menu.SubMenu>
              );
            }
            return (
              <Menu.Item
                key={`root-${index}`}
                className="route"
                onClick={() => AppActions.navigate(item.key)}>
                {item.label}
              </Menu.Item>
            );
          })}
        </Menu>
      </div>
      <div className="gutter" />

      <div className="main">
        <h1 className="title">{getRouteInstance(params)?.label || '-- --'}</h1>

        <div className="content">
          注意事項：台北律師公會對於任何包含於、經由、或聯結於本福利專區網站所獲得之資訊、內容或廣告(以下簡稱「資料」)，不聲明或保證其內容之正確性或可靠性；並且，對於您透過本福利專區上之廣告、資訊或要約而展示、購買或取得之任何產品、資訊或資料，本公會亦不負品質保證之責任，相關風險您應自行承擔。
        </div>

        {handleCustomComponent(props.location.search)[0] && (
          <div className="content">
            {handleCustomComponent(props.location.search)[1]}
          </div>
        )}

        {Array.isArray(records) && records.length === 0 && (
          <div className="content">
            <div className="waiting-text">敬請期待</div>
          </div>
        )}

        {records && (
          <div className="content">
            {records.map((record, index) => {
              let findIndex = expands?.findIndex(
                (expand) => expand.label === record.title,
              );
              let isExpand = expands ? expands[findIndex]?.expand : false;

              return (
                <div className="expand-container" key={`expand-${index}`}>
                  <div
                    className="expand-title"
                    onClick={() => {
                      let nextValues = [...expands];
                      nextValues[findIndex].expand = !isExpand;
                      setExpands(nextValues);
                    }}>
                    {record.title}
                    <Button
                      className="expand-button"
                      type="text"
                      icon={
                        isExpand ? (
                          <Minus size={20} color="var(--primaryColor)" />
                        ) : (
                          <Plus size={20} color="var(--primaryColor)" />
                        )
                      }
                    />
                  </div>
                  {isExpand && (
                    <div className="expand-content">
                      <RichTextPreview
                        key={record.id}
                        content={record.content}
                      />
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        )}
      </div>

      <Button
        shape="circle"
        size="large"
        className="rwd-fixed-button"
        icon={<BookOpen size={22} color="var(--primaryColor)" />}
        onClick={() => setMobileNavVisible(true)}
      />
      <MobileNavDrawer
        visible={mobile && mobileNavVisible}
        setVisible={setMobileNavVisible}
      />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  margin: var(--topNavBarHeight) auto 0px;
  max-width: var(--contentMaxWidth);
  width: 100%;
  min-height: 100vh;
  padding: 40px 20px;

  display: flex;
  justify-content: center;

  & > .menu {
    max-width: 210px;
    width: 100%;

    ${(props) => (props.mobile ? `display: none;` : `display: block;`)}

    & .header {
      border-bottom: 3px solid #c8b48c;
      text-align: center;
      font-weight: 500;
      font-size: 1rem;
      line-height: 2rem;
      color: var(--primaryColor);
    }

    & .ant-menu-root {
      border: 3px solid #c8b48c;
    }

    & .ant-menu-submenu-title {
      border-bottom: 3px solid #c8b48c;

      & > .ant-menu-title-content,
      .ant-menu-submenu-arrow {
        color: #ffffff;
      }
    }

    & .ant-menu-item,
    .ant-menu-item-only-child {
      border-bottom: 3px solid #c8b48c;
    }

    & .ant-menu-item:not(:last-child) {
      margin-top: 0px !important;
      margin-bottom: 0px !important;
      border-bottom: 3px solid #c8b48c;
    }

    & .ant-menu-inline .ant-menu-item:not(:last-child) {
      margin-bottom: 0px;
    }

    & .ant-menu-inline .ant-menu-submenu-title {
      margin-top: 0px;
      margin-bottom: 0px;
    }

    & .ant-menu-inline .ant-menu-item {
      margin-top: 0px;
      margin-bottom: 0px;
      border-bottom: 3px solid #c8b48c;
    }

    & .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
      background-color: #ffffff;
      font-weight: 500;
      color: #000000;

      &::after {
        border-right: 1px solid #c8b48c;
      }
    }

    & .route {
      background-color: var(--thirdColor);
      color: #ffffff;
    }

    & .route:last-child {
      border-bottom: 0px;
    }
  }

  & > .gutter {
    width: 20px;
    ${(props) => (props.mobile ? `display: none;` : `display: block;`)}
  }

  & > .main {
    max-width: 910px;
    width: 100%;
    padding: 10px;

    & > .title {
      text-align: center;
      padding-bottom: 15px;
      border-bottom: 6px solid var(--primaryColor);
      margin: 0 auto;
      max-width: 850px;
    }

    & > .content {
      margin: 0 auto;
      max-width: 850px;
      padding-top: 15px;
      padding-bottom: 15px;
      white-space: pre-wrap;

      & > .waiting-text {
        text-align: center;
        font-size: 1.25rem;
      }

      & > .expand-container {
        border-bottom: 3px solid #c8c8c8;
        padding-top: 10px;
        padding-bottom: 10px;

        & > .expand-title {
          display: flex;
          justify-content: space-between;
          align-items: center;
          color: var(--primaryColor);
          font-size: 0.95rem;
          cursor: pointer;
        }

        & > .expand-button {
        }

        & > .expand-content {
          padding-top: 15px;
          padding-bottom: 15px;
        }
      }
    }
  }

  & > .rwd-fixed-button {
    position: fixed;
    bottom: 20px;
    right: 20px;
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
    border: 1px solid var(--primaryColor);

    ${(props) => (props.mobile ? `display: block;` : `display: none;`)}
  }
`;

function MobileNavDrawer(props) {
  const {visible, setVisible} = props;
  const [dimension] = useOutlet('dimension');
  const mobile = dimension.rwd === 'mobile';

  return (
    <>
      <MobileNavDrawerBackdrop
        mobile={mobile}
        visible={visible}
        onClick={() => setVisible(false)}
      />
      <MobileNavDrawerWrapper mobile={mobile} visible={visible}>
        <div className="container">
          <Menu mode="inline" defaultOpenKeys={[`root-${0}`]}>
            <Menu.Item className="header">{TITLE}</Menu.Item>
            {ROUTES.map((item, index) => {
              if (item.children.length > 0) {
                return (
                  <Menu.SubMenu
                    key={`root-${index}`}
                    className="route"
                    title={item.label}>
                    {item.children.map((subroute, index) => (
                      <Menu.Item
                        key={`subroute-${index}`}
                        onClick={() => {
                          AppActions.navigate(subroute.key);
                          setVisible(false);
                        }}>
                        {subroute.label}
                      </Menu.Item>
                    ))}
                  </Menu.SubMenu>
                );
              }
              return (
                <Menu.Item
                  key={`subroute-${index}`}
                  className="route"
                  onClick={() => {
                    AppActions.navigate(item.key);
                    setVisible(false);
                  }}>
                  {item.label}
                </Menu.Item>
              );
            })}
          </Menu>
        </div>
      </MobileNavDrawerWrapper>
    </>
  );
}

const MobileNavDrawerBackdrop = styled.div`
  width: 100vw;
  height: 100vh;

  position: absolute;
  top: 0px;
  left: 0px;
  background-color: rgba(255, 255, 255, 0.5);

  ${(props) => (props.visible ? `display: block;` : `display: none;`)};
`;

const MobileNavDrawerWrapper = styled.div`
  width: 256px;
  height: 100vh;

  position: absolute;
  top: 83px;
  left: -256px;
  transition: 300ms;

  background-color: #ffffff;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  padding: 30px 10px;

  ${(props) => (props.visible ? `left: 0px;` : ``)}

  & > .container {
    & .header {
      border-bottom: 3px solid #c8b48c;
      text-align: center;
      font-weight: 500;
      font-size: 1rem;
      line-height: 2rem;
      color: var(--primaryColor);
    }

    & .ant-menu-root {
      border: 3px solid #c8b48c;
    }

    & .ant-menu-submenu-title {
      border-bottom: 3px solid #c8b48c;

      & > .ant-menu-title-content,
      .ant-menu-submenu-arrow {
        color: #ffffff;
      }
    }

    & .ant-menu-item,
    .ant-menu-item-only-child {
      border-bottom: 3px solid #c8b48c;
    }

    & .ant-menu-item:not(:last-child) {
      margin-top: 0px !important;
      margin-bottom: 0px !important;
      border-bottom: 3px solid #c8b48c;
    }

    & .ant-menu-inline .ant-menu-item:not(:last-child) {
      margin-bottom: 0px;
    }

    & .ant-menu-inline .ant-menu-submenu-title {
      margin-top: 0px;
      margin-bottom: 0px;
    }

    & .ant-menu-inline .ant-menu-item {
      margin-top: 0px;
      margin-bottom: 0px;
      border-bottom: 3px solid #c8b48c;
    }

    & .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
      background-color: #ffffff;
      font-weight: 500;
      color: #000000;

      &::after {
        border-right: 1px solid #c8b48c;
      }
    }

    & .route {
      background-color: var(--thirdColor);
      color: #ffffff;
    }

    & .route:last-child {
      border-bottom: 0px;
    }
  }

  max-width: 210px;
  width: 100%;
`;

export default withPageEntry(Welfare);
